import React, { useEffect } from "react";
import { useRazorpay } from "react-razorpay";
import { images } from "../Utils/Images";
import { useNavigate } from "react-router-dom";
import { normalCourtPayment } from "../Utils/API";
import { toast } from "react-hot-toast";
import { RazorPayKey } from "../Assets/Urls/Urls";

const RazerPayCapture = ({ orderState }) => {
    console.log('orderState : ', orderState);
    
    const { Razorpay } = useRazorpay();
    const navigate = useNavigate();
    const universalPayload = { booking_id: Number(orderState?.booking_id), amount: Number(orderState?.amount), payment_status: "Paid" }
    const onSuccess = () => {
        normalCourtPayment(universalPayload).then((res) => {
            console.log('res : ', res);
            if (res?.status == 'success') {
                navigate('/payment_successfull', { state: { bookingId: orderState?.booking_id } })
            } else {
                toast.error(res?.message);
            }
        })
    }

    //======================= RazorPay Checkout Funtion ===========================
    const handlePayment = () => {
        if (!RazorPayKey) {
            console.error("Razorpay API key is missing");
            return;
        }

        const order_id = orderState?.order_id; // Assuming you generate the order_id this way.
        const currency = "INR"; // Default currency, adjust if needed.

        const options = {
            key: RazorPayKey,
            amount: Math.round(orderState?.amount * 100), // Convert to smallest currency unit
            currency,
            name: "Players Turf",
            description: "Players Turf Payment",
            image: images?.Logo,
            order_id,
            handler: (response) => {
                console.log("Payment successful", response);
                (response?.razorpay_payment_id && response?.razorpay_order_id) ? onSuccess() : navigate('/payment_failed')
            },
            prefill: {
                email: orderState?.Userdetails?.email || "",
                contact: orderState?.Userdetails?.phone || "",
                name: `${orderState?.Userdetails?.first_name || ""} ${orderState?.Userdetails?.last_name || ""}`,
            },
            readonly: {
                email: true,
                contact: true,
                name: true,
            },
            theme: {
                color: "#F37254", // Update to your preferred color
            },
        };

        try {
            const razorpayInstance = new Razorpay(options);
            razorpayInstance.open();
        } catch (error) {
            console.error("Razorpay failed to initialize", error);
            // onFailedPressed(error);
        }
    };

    useEffect(() => {
        if (orderState?.order_id) {
            handlePayment();
        }
    }, [orderState?.order_id]);

    return (
        <></>
    );
};

export default RazerPayCapture;
