import { Toaster } from "react-hot-toast";
import { Route, Routes, useLocation } from "react-router-dom";
import Booking from "../pages/Booking";
import ExplorBySports from "../pages/ExplorBySports";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Navbar from "../pages/Navbar";
import NotFound from "../pages/NotFound";
import PaymentSuccessfull from "../pages/PaymentSuccessfull";
import Policies from "../pages/Policies";
import RefundPolicy from "../pages/RefundPolicy";
import SlotBooking from "../pages/SlotBooking";
import Terms from "../pages/Terms";
import Venue from "../pages/Venue";
import AllBookings from "../pages/AllBookings";
import BookingDetails from "../pages/BookingDetails";

const ProjectRoutes = () => {
  const location = useLocation();
  // Define the paths where the Navbar should be displayed
  const navbarPages = ["/booking", "/venue_screen", "/slot_booking", "/explor", "/bookingsDetail", "/booking_summary"];

  return (
    <>
      {/* Conditionally render Navbar */}
      {navbarPages.includes(location.pathname) && <Navbar />}

      {/* Define Routes */}
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/policy" element={<Policies />} />
        <Route path="/refund" element={<RefundPolicy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/payment_successfull" element={<PaymentSuccessfull />} />

        {/* Protected Routes */}
        <Route
          path="/booking"
          element={<Booking />}
        />
        <Route
          path="/bookingsDetail"
          element={<AllBookings />}
        />
        <Route
          path="/venue_screen"
          element={<Venue />}
        />

        <Route
          path="/slot_booking"
          element={<SlotBooking />}
        />

        <Route
          path="/explor"
          element={<ExplorBySports />}
        />

        <Route
          path="/booking_summary"
          element={<BookingDetails />}
        />

        {/* Catch-all route for invalid paths */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </>
  );
};

export default ProjectRoutes;
