import React, { useState, useRef } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin, setUserDetails } from '../Utils/Redux/userSlice/userSlice';
import { LoginAPI } from '../Utils/API';
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/Images/logo.jpeg';
import Error from './Error';
import { useForm } from 'react-hook-form';
import { PRODUCTION } from '../Assets/Urls/Urls';

export default function Login({ openLoginModal, setOpenLoginModal, fromBooking }) {
    const userLocation = useSelector((state) => state.user.userLocation);
    const Userdetails = useSelector((state) => state.user?.userDetails);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [step, setStep] = useState(1); // Step 1: Login, Step 2: OTP Verification
    const [generatedOtp, setGeneratedOtp] = useState(null);
    const [otpFields, setOtpFields] = useState(['', '', '', '']);
    const otpInputRefs = [useRef(), useRef(), useRef(), useRef()];
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const phone_number = watch('phone');
    const CloseModal = () => {
        reset();
        setOpenLoginModal(false);
        setStep(1);
        setOtpFields(['', '', '', '']);
    };

    const onSubmit = async (data) => {
        const otp = Math.floor(1000 + Math.random() * 9000); // Generate a random 4-digit OTP
        setGeneratedOtp(otp);
        console.log('Generated OTP:', otp); // Remove this in production
        setStep(2); // Move to OTP verification step
    };

    const handleOtpChange = (value, index) => {
        if (!/^\d?$/.test(value)) return; // Only allow single digits
        const updatedOtp = [...otpFields];
        updatedOtp[index] = value;
        setOtpFields(updatedOtp);

        if (value && index < otpFields.length - 1) {
            otpInputRefs[index + 1].current.focus();
        }
    };

    const handleOtpKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otpFields[index] && index > 0) {
            otpInputRefs[index - 1].current.focus();
        }
    };

    const verifyOtp = async () => {
        const enteredOtp = otpFields.join('');
        if (parseInt(enteredOtp) === generatedOtp) {
            try {
                const data = { pin_code: userLocation?.pin_code, phone: phone_number };
                const response = await LoginAPI(data);
                console.log('Login response', response);
                if (response?.data?.status === 'success') {
                    localStorage.setItem('Token', response?.data?.data?.access);
                    localStorage.setItem('RefreshToken', response?.data?.data?.refresh);
                    dispatch(setUserDetails(response?.data?.data));
                    dispatch(setLogin(true));
                    setOpenLoginModal(false);
                    setStep(1);
                    reset();
                    fromBooking ? navigate('/slot_booking') : navigate('/booking');
                }
            } catch (error) {
                console.log('error', error);
            }
        } else {
            alert('Invalid OTP');
        }
    };

    return (
        <Transition.Root show={openLoginModal && Userdetails === undefined} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setOpenLoginModal(false)}>
                <div className="fixed inset-0 bg-black bg-opacity-25" />
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                        <Dialog.Panel className="w-full max-w-lg p-6 bg-white rounded-2xl shadow-xl">
                            {step === 1 && (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="space-y-3">
                                        <div>
                                            <img src={logo} className="mx-auto h-[30%] w-[30%]" alt="Logo" />
                                            <h2 className="text-lg font-bold text-black">
                                                Welcome, <span className="text-red-400">Admin!</span>
                                            </h2>
                                            <h5 className="text-sm font-medium text-gray-400">
                                                Please Sign In to Admin Dashboard!
                                            </h5>
                                        </div>

                                        <div className="space-y-4">
                                            <div>
                                                <label
                                                    htmlFor="number"
                                                    className="block text-sm font-medium text-gray-500"
                                                >
                                                    Number<span className="text-red-500">*</span>
                                                </label>
                                                <input
                                                    id="number"
                                                    {...register('phone', { required: 'Number is required' })}
                                                    type="text"
                                                    placeholder="Enter your Number"
                                                    maxLength={10}
                                                    className="w-full px-3 py-2 border rounded bg-neutral-100"
                                                />
                                                {errors.phone && <Error message={errors.phone.message} />}
                                            </div>
                                            <button
                                                type="submit"
                                                className="w-full px-3 py-2 font-semibold text-white bg-red-400 rounded hover:bg-red-500"
                                            >
                                                Login
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )}

                            {step === 2 && (
                                <div>
                                    <h3 className="mb-4 text-lg font-bold text-center text-black">Enter OTP</h3>
                                    {(PRODUCTION || phone_number == "9658914454" || phone_number == "9503603955") && <p className="mb-2 text-center text-gray-500">
                                        Your OTP is <span className="font-bold text-black">{generatedOtp}</span>
                                    </p>}
                                    <div className="flex justify-center gap-2">
                                        {otpFields.map((digit, index) => (
                                            <input
                                                key={index}
                                                ref={otpInputRefs[index]}
                                                type="text"
                                                value={digit}
                                                onChange={(e) => handleOtpChange(e.target.value, index)}
                                                onKeyDown={(e) => handleOtpKeyDown(e, index)}
                                                maxLength={1}
                                                className="w-10 h-10 text-center text-lg border rounded"
                                            />
                                        ))}
                                    </div>
                                    <button
                                        onClick={verifyOtp}
                                        className="w-full px-3 py-2 mt-4 font-semibold text-white bg-red-400 rounded hover:bg-red-500"
                                    >
                                        Verify OTP
                                    </button>
                                </div>
                            )}

                            <div className="flex justify-end mt-4">
                                <button
                                    onClick={CloseModal}
                                    className="px-3 py-1 text-gray-500 bg-gray-200 rounded hover:bg-gray-300"
                                >
                                    Close
                                </button>
                            </div>
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
