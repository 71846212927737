import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    sports: [],
    bookingCourt: null,
    allLocations: [],
    allVenues: [],
}

export const sportSlice = createSlice({
    name: 'sports',
    initialState,
    reducers: {
        setSports(state, action) {
            state.sports = action.payload;
        },
        setBookingCourt(state, action) {
            state.bookingCourt = action.payload;
        },
        setAllLocations(state, action) {
            state.allLocations = action.payload;
        },
        setAllVenues(state, action) {
            state.allVenues = action.payload;
        },
    }
})

export const { setSports, setBookingCourt, setAllLocations, setAllVenues } = sportSlice.actions;
export default sportSlice.reducer