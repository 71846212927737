import React, { useEffect, useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Autoplay, Mousewheel, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Promotions } from '../Utils/API'

function PromotionsSlider() {
    const [promotions, setPromotions] = useState([])

    useEffect(() => {
        try {
            Promotions().then(res => {
                if (res?.data?.status === 'success' && res?.data?.data?.length > 0) {
                    const activePromotions = res?.data?.data.filter(item => item.slide_is_active)
                    setPromotions(activePromotions)
                }
            })
        } catch (error) {
            console.log('error', error)
        }
    }, [])

    return (
        <Swiper
            spaceBetween={30}
            slidesPerView={3}  // Default: 3 slides on large/medium screens
            mousewheel={true}
            autoplay={{
                delay: 5500,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            breakpoints={{
                320: { // For small screens
                    slidesPerView: 1,
                },
                768: { // For medium screens
                    slidesPerView: 2,
                },
                1024: { // For large screens
                    slidesPerView: 3,
                },
            }}
            modules={[Autoplay, Pagination, Mousewheel]}
            className='my-4'
        >
            {
                promotions?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="relative w-96 h-36 rounded-xl overflow-hidden shadow-md">
                            {/* Background Image */}
                            <div
                                className="absolute inset-0 bg-cover bg-center"
                                style={{
                                    backgroundImage: `url('${encodeURI(item.slide_url)}')` // Replace with your image URL
                                }}
                            ></div>
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}

export default PromotionsSlider
