import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

function ExplorBySports() {
    const allSports = useSelector((state) => state.sport.sports);
    const [selectedTab, setSelectedTab] = useState(0);
    const [tabSport, setTabSport] = useState(allSports[0]);
    const location = useLocation();
    const data = location.state;
    const venues = useSelector(state => state.sport.allVenues);

    // Use useMemo for filtering venues based on the selected sport
    const filteredVenues = useMemo(() => {
        return venues?.filter(venue =>
            venue.sports.some(sport => sport?.sport?.id === tabSport?.id)
        );
    }, [venues, tabSport]);  // Recalculate when venues or tabSport change

    return (
        <Tabs
            selectedIndex={selectedTab} // Controlled by state
            onSelect={(index) => setSelectedTab(index)} // Updates state when the tab changes
        >
            <TabList className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-8 border-b border-gray-200 mt-2 bg-white shadow">
                {allSports?.map((item, index) => (
                    <Tab
                        key={item.id || index}
                        onClick={() => setTabSport(item)}
                        className={`py-2 px-4 text-center cursor-pointer transition duration-300 ease-in-out ${index === selectedTab
                            ? 'border-b-2 border-red-500 text-red-500 font-bold'
                            : 'text-gray-600 hover:text-red-500'
                            }`}
                    >
                        {item.sport_name}
                    </Tab>
                ))}
            </TabList>

            {/* Dynamically render TabPanels */}
            {allSports.map((item, index) => (
                <TabPanel
                    key={item.id || index}
                    className="flex p-4 overflow-hidden"
                >
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
                        {filteredVenues?.length > 0 ? (
                            filteredVenues.map((venue) => (
                                <NavLink
                                    key={venue.id}
                                    to="/venue_screen"
                                    state={{ ...venue, isBooking: false }}
                                    className="max-w-sm bg-white rounded-lg shadow-md overflow-hidden border"
                                >
                                    <div className="relative">
                                        <img
                                            src={venue?.profile_image[0]?.media_url}
                                            alt={venue.name}
                                            className="w-full h-48 object-cover"
                                        />
                                    </div>
                                    <div className="p-4">
                                        <div className="flex justify-between items-center">
                                            <h2 className="text-lg font-semibold">{venue.name}</h2>
                                        </div>
                                        <p className="text-gray-500">{venue.address}</p>
                                    </div>
                                </NavLink>
                            ))
                        ) : (
                            <p className="text-gray-500">No venues available for this sport.</p>
                        )}
                    </div>
                </TabPanel>
            ))}
        </Tabs>
    );
}

export default ExplorBySports;
