import Lottie from 'lottie-react';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Assumes you are using React Router
import { images } from '../Utils/Images';
import { getBookingDetails } from '../Utils/API';

function PaymentSuccessfull() {
    const [countdown, setCountdown] = useState(5); // Initialize countdown
    const [apiResponse, setApiResponse] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state
    console.log('payment successful data', data)
    useEffect(() => {
        const fetchData = async () => {
            try {
                await getBookingDetails(data?.bookingId).then(res => {
                    console.log('booking details Data============', res?.data?.data)
                    setApiResponse(res?.data?.data)
                })
            } catch (error) {
                console.log('error', error)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        // Countdown logic
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer); // Clear timeout on component unmount
        } else {
            navigate('/bookingDetail', { state: { apiResponse } }); // Redirect to booking summary
        }
    }, [countdown, navigate]);

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="w-full max-w-sm bg-white border-2 border-gray-200 rounded-md p-5 shadow-lg">
                <div className="w-[100%] h-[100%] mx-auto">
                    <Lottie animationData={images.PaymentSuccess} loop={true} />
                </div>
                <h1 className="mt-5 text-center text-xl font-semibold capitalize text-gray-700">
                    Payment Successful
                </h1>
                <p className="capitalize mt-3 text-center text-gray-600">
                    You will be redirected to the booking summary page in{' '}
                    <span className="font-bold">{countdown}</span> seconds...
                </p>
            </div>
        </div>
    );
}

export default PaymentSuccessfull;
