import { AddCircle, MinusCirlce } from 'iconsax-react';
import { Banknote, CalendarCheck2, CalendarDays, Clock, LandPlot, MapPin, Phone, ShoppingCart, Timer, Trash2 } from 'lucide-react';
import moment from 'moment';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from 'react-hook-form'; // Use useForm hook
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { formBtn1, inputClass } from '../Utils/CustomClasses.jsx';
import { bookCourt, courtVenueSportFilter, getSlots, normalCourtPayment, partialBookingCourtPayment, paymentOrderId, splitPaymentCourtPayment } from '../Utils/API.jsx';
import { processOnlinePayment } from '../Utils/ReUsableFuntions.jsx';
import { useNavigate, useNavigation } from 'react-router-dom';
import RazerPayCapture from './RazerPayCapture.jsx';
import toast from 'react-hot-toast';

function SlotBooking() {
    // Initialize useForm
    // console.log('route====================', route)
    const navigation = useNavigate();
    const venueDetails = useSelector(state => state?.sport?.bookingCourt);    
    const Userdetails = useSelector(state => state.user?.userDetails)
    const { register, handleSubmit, setValue, watch, reset, } = useForm();
    // const { bookingDetails: bookingData } = route.params
    // const [bookingDetails, setBookingDetails] = useState(bookingData)
    const [timeOpen, setTimeOpen] = useState(false);
    const [duration, setDuration] = useState(1);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [availableSlots, setAvailableSlots] = useState([]);
    const [bookingRegistered, setBookingRegistered] = useState(null);
    const [selectedCourtState, setSelectedCourtState] = useState(null);
    const [courtList, setCourtList] = useState([]);
    const [splitAmountData, setSplitAmountData] = useState({
        slotPrice: 0.0,
        addonPrice: 0.0,
        taxPrice: 0.0,
        totalAmount: 0.0
    });
    const [partialPayment, setPartialPayment] = useState(false)
    const [partialPaymentDetails, setPartialPaymentDetails] = useState({
        slotAmount: 0,
        addonAmount: 0,
        taxAmount: 0,
        totalAmount: 0
    });
    const selectedSport = watch('sport')
    const selectedCourt = watch('court')
    const dropdownRef = useRef(null);
    const unitSlotPriceObject = venueDetails?.sports_price?.find(sportPriceData => sportPriceData?.sport == selectedSport?.value && sportPriceData?.court == selectedCourt)

    const sportsOptions = venueDetails?.sport_available_text?.map((sport) => ({
        value: sport.id,
        label: sport.sport_name,
        image: sport.sport_image,
    }));

    const generateSlots = (startTime, endTime, bookedSlots, blockedSlots, slotDuration = 60) => {
        const slots = [];
        const start = moment(startTime, 'HH:mm');
        const end = moment(endTime, 'HH:mm');

        while (start.isBefore(end)) {
            const slot = start.format('HH:mm');
            slots.push(slot);
            start.add(slotDuration, 'minutes');
        }

        console.log("Generated Slots:", slots); // Log generated slots
        const allSlots = slots.filter(slot => !bookedSlots.includes(slot) && !blockedSlots.includes(slot));
        console.log("Filtered Slots:", allSlots); // Log available slots after filtering

        return allSlots;
    };


    const getAllSlots = () => {
        const payload = {
            venue_id: venueDetails?.id,
            date: moment(selectedDate).format('YYYY-MM-DD'),
            court_id: selectedCourt
        };

        getSlots(payload).then(res => {
            if (res?.data) {
                const { start_time, end_time, booked_slots, blocked_slots } = res?.data?.data;

                if (start_time && end_time) {
                    const availableSlots = generateSlots(start_time, end_time, booked_slots, blocked_slots, selectedCourtState?.slot_duration);
                    setAvailableSlots(availableSlots);
                } else {
                    console.log("Invalid start or end time");
                }
            }
        });
    };


    const handleSlotClick = (slot) => {
        setSelectedSlot(slot);
        setValue('slot', slot); // Update form state
        setTimeOpen(false);
    };

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div
            onClick={onClick}
            ref={ref}
            className="flex items-center border border-gray-300 rounded-md px-3 py-2 cursor-pointer w-full bg-white"
        >
            <input
                value={value}
                readOnly
                placeholder="Pick a date"
                className="flex-1 w-[10%] bg-transparent focus:outline-none text-gray-700"
            />
            <CalendarDays className="text-gray-500 ml-2" />
        </div>
    ));

    const discardFunc = () => {
        reset(); // Reset form state
        setValue('sport', undefined)
        setSelectedSlot(null);
        setDuration(1);
    }

    const [orderState, setOrderState] = useState({});
    const generateOrderId = async (amount, bookingType, bookingId) => {
        const { status, order_id, currency } = await paymentOrderId({
            amount: Math.round(amount * 100),
            currency: 'INR',
            booking_type: bookingType,
            booking_id: bookingId
        });

        if (status === "created") {
            setOrderState({ order_id, currency, status, amount, Userdetails, booking_id: bookingId });
        } else {
            toast.error("Payment failed");
        }
    }


    const onSubmit = (data) => {
        try {
            const formattedPayload = {
                start_date: moment(selectedDate).format('YYYY-MM-DD'),
                end_date: moment(selectedDate).format('YYYY-MM-DD'),
                start_time: moment(selectedSlot, "HH:mm").format('HH:mm:ss'),
                end_time: moment(selectedSlot, "HH:mm").add(duration, 'hours').format('HH:mm:ss'),
                court: selectedCourt,
                sport: selectedSport?.value,
                venue: venueDetails?.id,
                user: Userdetails?.id,
                amount_paid: (!isNaN(parseFloat(unitSlotPriceObject?.sport_price)) && !isNaN(Number(duration)))
                    ? (parseFloat(unitSlotPriceObject?.sport_price) * Number(duration)).toFixed(2)
                    : 0.00, // Default to "0.00" if invalid
                payment_status: "Pending",
                booked_from: "WEB APP",
                addons: [],
                split_payment: false,
                split_payment_members: [],
                partial_payment: false,
                partial_payment_amount: 0,
                coupon: null
            }
            const universalPayload = { booking_id: Number(bookingRegistered?.id), amount: Number(formattedPayload?.amount_paid), payment_status: "Paid" }
            console.log('updatedData=============', formattedPayload); // Handle form submission
            const successObject = {
                bookingDetails: bookingRegistered,
                // bookingDate: bookingDetails?.booking_date,
                slotTime: `${formattedPayload?.start_time} - ${formattedPayload?.end_time}`,
                paymentStatus: "Success",
                amountPaid: formattedPayload?.amount_paid
            }
            const onSuccess = () => {
                // api call for updating it to payment status to success
                console.log('Payload : ', universalPayload);

                normalCourtPayment(universalPayload).then((res) => {
                    console.log('res : ', res);
                    if (res?.status == 'success') {
                        toast.success(res?.message);
                        // navigation.navigate('bookingSuccess', { bookingId: res?.data?.booking_id, bookingData: bookingDetails, afterPayment: successObject })
                    } else {
                        toast.success(res?.message);
                    }
                })
            }

            const onFailed = () => {
                toast.error('Booking Failed')
                // navigation.navigate('bookingFailed')
            }

            const splitPaymentOnSuccess = () => {
                console.log("splitPaymentOnSuccess");
                splitPaymentCourtPayment({ ...universalPayload, amount: Number(splitAmountData?.totalAmount) }).then((res) => {
                    console.log('res : ', res);
                    if (res?.status == 'success') {
                        toast.success(res?.message);
                        // navigation.navigate('bookingSuccess', { bookingId: res?.data?.booking_id, bookingData: bookingDetails, afterPayment: { ...successObject, amountPaid: Number(splitAmountData?.totalAmount) } })
                    } else {
                        toast.error(res?.message);
                    }
                }).catch((error) => {
                    console.log('error : ', error?.response);
                })

            }

            const partialPaymentOnSuccess = () => {
                console.log("partialPaymentOnSuccess");
                try {
                    partialBookingCourtPayment({ ...universalPayload, amount: Number(partialPaymentDetails?.totalAmount) }).then((res) => {
                        console.log('res : ', res);
                        if (res?.status == 'success') {
                            toast.success(res?.message);
                            // navigation.navigate('bookingSuccess', { bookingId: res?.data?.booking_id, bookingData: bookingDetails, afterPayment: { ...successObject, amountPaid: Number(partialPaymentDetails?.totalAmount) } })
                        } else {
                            toast.error(res?.message);
                        }
                    })
                } catch (error) {
                    console.log('error: ', error)
                }
            }

            const internalRoutePayload = {
                onSuccess: partialPayment ? partialPaymentOnSuccess : onSuccess,
                onFailed
            }
            bookCourt(formattedPayload).then(res => {
                console.log('res====================', res)
                if (res?.data?.status == 'success') {
                    setBookingRegistered(res?.data?.data?.booking_id)
                    generateOrderId(formattedPayload?.amount_paid, "court", res?.data?.data?.booking_id?.id)
                    // processOnlinePayment(navigation, formattedPayload?.amount_paid, Userdetails, () => () => internalRoutePayload?.onSuccess(res?.data?.data?.booking_id), internalRoutePayload?.onFailed, "court", res?.data?.data?.booking_id?.id)
                } else {
                    toast.error(res?.data?.message)
                }
            })
        } catch (error) {
            console.log('error====================', error)
        }
    };

    const courtListApi = (venueId, sportId) => {
        courtVenueSportFilter({ venue_id: venueId, sport_id: sportId })
            .then((res) => {
                if (res?.data?.length > 0) {
                    console.log('courtListApi res====================', res?.data);
                    setCourtList(res?.data);
                    return res?.data;
                }
                setCourtList([]);
                return [];
            })
            .catch((err) => {
                console.log(err);
                return [];
            });
    };


    useMemo(() => {
        getAllSlots();
    }, [selectedCourt, selectedDate]);

    useEffect(() => {
        setSelectedCourtState(venueDetails?.courts?.find(court => court?.id == selectedCourt));
    }, [selectedCourt]);

    useEffect(() => {
        if (selectedSport && venueDetails?.id) {
            console.log('selectedSport====================', selectedSport);
            courtListApi(venueDetails?.id, selectedSport?.value);
        }
    }, [selectedSport]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setTimeOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="grid grid-cols-1 place-items-center lg:grid-cols-6 md:grid-cols-1 sm:grid-cols-1 gap-8 my-4">
            <div />
            {/* =================== Form ======================= */}
            <form onSubmit={handleSubmit(onSubmit)} className="md:col-span-1 lg:col-span-2 sm:col-span-1 mx-4 gap-4 w-full">
                <div className="border-2 border-slate-200 rounded-lg">
                    {/* =================== Venue Details ============= */}
                    <div className='border-b-2 p-4 '>
                        <p className="font-bold text-xl capitalize">{venueDetails?.name}</p>
                        <div className='flex gap-2'>
                            <MapPin color='red' strokeWidth={1.25} size={20} />
                            <p className="capitalize">{venueDetails?.address}</p>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <Phone color='red' strokeWidth={1.25} size={20} />
                            <p className="capitalize">{venueDetails?.contact_number}</p>
                        </div>
                    </div>
                    {/*================= Sports Dropdown =================== */}
                    <div className="grid grid-cols-2 items-center mt-4 px-4">
                        <p className="capitalize font-semibold text-lg">Sport</p>
                        <Select
                            options={sportsOptions}
                            onChange={(selectedOption) => {
                                setValue('sport', selectedOption); // Update form state
                            }}
                            getOptionLabel={(e) => (
                                <div className="flex items-center gap-2">
                                    <img
                                        src={e.image}
                                        alt={e.label}
                                        className="w-6 h-6 rounded-full"
                                    />
                                    <span>{e.label}</span>
                                </div>
                            )}
                            getOptionValue={(e) => e.value}
                            placeholder="Select Sport"
                        />
                    </div>

                    {/* ============= Court Selection DropDown ========================= */}
                    <div className="grid grid-cols-2 items-center mt-4 px-4">
                        <p className="capitalize font-semibold text-lg">Court</p>
                        <select className={inputClass} {...register('court')} disabled={!selectedSport}>
                            <option value="">Select Court</option>
                            {courtList?.map((item, index) => (
                                <option key={index} value={item?.id}>
                                    {item?.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* =============== Date Picker ====================== */}
                    <div className="grid grid-cols-2 items-center mt-4 px-4">
                        <p className="capitalize font-semibold text-lg">Date</p>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => {
                                setSelectedDate(date);
                                setValue('date', date); // Update form state
                            }}
                            className='w-fit'
                            dateFormat="yyyy-MM-dd"
                            minDate={new Date()} // Disable dates before today
                            customInput={<CustomInput />}
                        />
                    </div>

                    {/* ============= Duration ========================= */}
                    <div className="grid grid-cols-2 items-center mt-4 px-4">
                        <p className="capitalize font-semibold text-lg">Duration</p>
                        <span className="capitalize font-semibold text-lg">{selectedCourtState?.slot_duration ? selectedCourtState?.slot_duration : 0} min</span>
                        {/* <div className="flex items-center justify-between">
                            <button type='button' onClick={() => duration > 1 && setDuration(duration - 1)}>
                                <MinusCirlce
                                    variant="Bold"
                                    size={32}
                                    color={duration === 1 ? '#cbd5e1' : '#EF4444'}
                                />
                            </button>
                            <span className="capitalize font-semibold text-lg">{duration} hr</span>
                            <button type='button' onClick={() => setDuration(duration + 1)}>
                                <AddCircle variant="Bold" size={32} color="#EF4444" />
                            </button>
                        </div> */}
                    </div>

                    {/* ============== Slot Time Picker ================ */}
                    <div className="grid grid-cols-2 items-center mt-4 px-4">
                        <p className="capitalize font-semibold text-lg">Start Time</p>
                        <div className="relative w-full">
                            <button
                                type='button'
                                className={`${inputClass} flex justify-between items-center`}
                                onClick={() => setTimeOpen(!timeOpen)}
                            >
                                <span className="font-semibold">{selectedSlot || 'Select a slot'}</span>
                                <Clock strokeWidth={1.25} size={20} />
                            </button>
                            {timeOpen && (
                                <div ref={dropdownRef} className="absolute mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10 max-h-60 overscroll-y-auto overflow-scroll">
                                    <div className="grid grid-cols-2 gap-2 p-2">
                                        {availableSlots.map((slot, index) => (
                                            <button
                                                key={index}
                                                className={`p-2 rounded-lg text-center border ${selectedSlot === slot
                                                    ? 'bg-red-500 text-white'
                                                    : 'bg-white text-gray-800 hover:bg-gray-100'
                                                    }`}
                                                onClick={() => handleSlotClick(slot)}
                                            >
                                                {slot}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* ============== Form Submit Button ============= */}
                    <div className='px-4 pb-2'>
                        <button
                            type="submit"
                            className={`${formBtn1} !w-full mt-4 ${(!selectedCourt || selectedCourt === '' || !selectedSport || !selectedSlot) ? 'bg-slate-300 cursor-not-allowed' : ''}`}
                            disabled={!selectedCourt || selectedCourt === '' || !selectedSport || !selectedSlot}
                        >
                            {(!unitSlotPriceObject) ? 'Add To Cart' : `Procceed To Pay ${unitSlotPriceObject?.sport_price * duration}`}
                        </button>
                        <RazerPayCapture orderState={orderState} />
                    </div>
                </div>
            </form>

            {/* =================== Cart ======================= */}
            <div className='md:col-span-1 sm:col-span-1 lg:col-span-2 rounded-lg border-2 w-full '>
                {
                    (!selectedCourt || selectedCourt === '' || !selectedSport || !selectedSlot) ? (
                        <div className="flex flex-col items-center justify-center h-full">
                            <ShoppingCart strokeWidth={1.25} size={80} color="#64748B" />
                            <p className="font-semibold text-lg text-slate-500">Empty Cart</p>
                        </div>
                    ) : (
                        <div>
                            <div className="flex justify-between items-center border-b-2 px-4 py-2">
                                <p className="font-bold text-xl capitalize">Cart</p>
                                {/* ============== Discard Button ==================== */}
                                <button type="button" onClick={discardFunc}>
                                    <Trash2 color="red" />
                                </button>
                            </div>
                            <div>
                                <div className="flex items-center gap-2  px-2 mt-4">
                                    <LandPlot color='red' strokeWidth={1.25} />
                                    <span className="font-medium">{selectedSport?.label}</span>
                                </div>
                                <div className="flex items-center justify-between px-2 mt-4">
                                    <div className="flex items-center gap-2">
                                        <CalendarCheck2 color='red' strokeWidth={1.25} />
                                        <span className="font-medium">
                                            {moment(selectedDate).format("DD, MMMM YYYY")}
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Clock color='red' strokeWidth={1.25} />
                                        <span className="font-medium">{selectedSlot}</span>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between px-2 mt-4">
                                    <div className="flex items-center gap-2">
                                        <Timer color='red' strokeWidth={1.25} />
                                        <span className="font-medium capitalize">{selectedCourtState?.slot_duration} min</span>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between px-2 mt-4">
                                    <div className="flex items-center gap-2">
                                        <Banknote color='red' strokeWidth={1.25} />
                                        <span className="font-medium capitalize">{unitSlotPriceObject?.sport_price * duration}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div />
        </div>

    );
}

export default SlotBooking;
