import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPincodeApi } from '../Utils/API';
import { inputClass } from '../Utils/CustomClasses';
import { setAllLocations } from '../Utils/Redux/sportSlice/sportSlice';
import { setUserLocation } from '../Utils/Redux/userSlice/userSlice';

function LocationModel({ open, setOpen, requestLocationPermission }) {
    const cancelButtonRef = useRef(null);
    const dispatch = useDispatch();
    const allLocations = useSelector(state => state.sport.allLocations)
    const { register, handleSubmit, setValue, watch, reset } = useForm();
    const onSubmit = (data) => {
        const location = JSON.parse(data.location)
        dispatch(setUserLocation(location))
        setOpen(!open)
    }
    useEffect(() => {
        try {
            const formattedFilterPayload = {
                "page": 1,
                "page_size": 10,
                "city": "",
            }

            getAllPincodeApi(formattedFilterPayload).then(res => {
                dispatch(setAllLocations(res?.data?.results))
            })
        } catch (error) {
            console.log('error', error);
        }
    }, [])
    return (
        <Transition.Root show={!open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[99]"
                initialFocus={cancelButtonRef}
                onClose={() => setOpen(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-md transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-[99] w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="flex items-center">
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-lg font-semibold leading-6 text-gray-900 font-tbPop"
                                                >
                                                    Please Select Location
                                                </Dialog.Title>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='px-4'>
                                        <select {...register('location')} className={inputClass} id="location">
                                            {allLocations?.map((item, index) => (
                                                <option value={JSON.stringify(item)} key={index}>
                                                    {item?.city} | {item?.pin_code}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="submit"
                                            className="inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 sm:ml-3 sm:w-auto"
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default LocationModel