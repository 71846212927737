import axios from "axios";
import toast from "react-hot-toast";
import { setLogin } from "./Redux/userSlice/userSlice";

let store
const baseURL = process.env.REACT_APP_BASE_API_URL
export const injectStore = _store => {
    store = _store;
}

const customAxios = axios.create({
    baseURL: baseURL
})

const requestHandler = (request) => {
    const userToken = localStorage.getItem('Token')
    request.headers.Authorization = `Bearer ${userToken}`;
    return request;
}

const responseHandler = (response) => {
    if (response.status === 401) {
        return response;
    }
    return response;
}

const errorHandler = (error) => {
    if (error.response.data.code === 'token_invalid') {
        toast.error('Session has expired, please login again.')
        store.dispatch(setLogin(false))
        window.location = '/'
        return
    }
    return Promise.reject(error)
}

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
)

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export default customAxios;
