import React from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  // const textSize = `text-sm md:text-base lg:text-lg xl:text-lg`;
  const navigate = useNavigate();
  const navigationManager = (uri) => {
    navigate("/" + uri);
  };
  return (
    <div className="bg-gray-100 h-screen flex flex-col flex-1 justify-between">
      <nav className="bg-gradient-to-r from-primary to-[#ef4444] text-white py-4">
        <div className="container mx-auto flex justify-between items-center px-6">
          {/* Logo Section */}
          <div className="flex items-center">
            <img 
              src='https://players-turf-storage.s3.ap-south-1.amazonaws.com/profile/Super_logo.jpeg'
              alt="Players Turf Logo" 
              className="h-8 w-8 sm:h-10 sm:w-10 rounded-full"
            />
            <h1 className="text-2xl font-bold ml-2 hidden sm:block">Players Turf</h1>
          </div>

          {/* Button Section */}
          <div className="flex gap-8">
            <div className="border-2 border-white rounded-lg">
              <button
                className="bg-white border-2 border-[#ef4444] text-primary px-6 py-2 rounded-lg shadow-lg transition ease-in-out duration-300 delay-75 
                hover:bg-gradient-to-br hover:from-[#fff] hover:to-[#ef4444] hover:text-white"                
                onClick={() => navigationManager("booking")}
              >
                Let's Booking
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="bg-gray-100">
        {/* Hero Section */}
        <section className="bg-gradient-to-r from-primary to-[#ef4444] text-white py-20">
          <div className="container mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold">
              Welcome to Players Turf
            </h1>
            <p className="mt-4 text-lg md:text-xl">
              Book your favorite sports venues easily and quickly!
            </p>
          </div>
        </section>

        {/* Features Section */}
        <section className="container mx-auto py-16 pb-5 px-6 ">
          <h2 className="text-3xl font-semibold text-primary">Our Features</h2>
          <div className="my-12 grid gap-8 grid-cols-1 md:grid-cols-3">
            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <h3 className="text-2xl font-semibold text-primary">
                Easy Booking
              </h3>
              <p className="mt-4 text-gray-600">
                Book your favorite sports venue with just a few clicks on the mobile app!
              </p>
            </div>
            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <h3 className="text-2xl font-semibold text-primary">
                Real-Time Availability
              </h3>
              <p className="mt-4 text-gray-600">
                Check real-time availability of venues in the mobile app to avoid scheduling
                conflicts.
              </p>
            </div>
            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <h3 className="text-2xl font-semibold text-primary">
                Affordable Pricing
              </h3>
              <p className="mt-4 text-gray-600">
                Get the best deals on venues and enjoy your favorite sports
                affordably on the mobile app.
              </p>
            </div>
          </div>
        </section>
        <section className="container mx-auto py-16 pt-5 px-6">
          <h2 className="text-3xl font-semibold text-primary">
            Privacy Policy and Terms
          </h2>
          <div className="my-12 grid grid-cols-1 md:grid-cols-3 gap-3">
            <div className="bg-white p-4 shadow-lg rounded-lg">
              <h3 className="text-2xl font-semibold text-primary">Terms</h3>
              <p className="mt-4 text-gray-600">
                Please read these terms and conditions carefully before using
                Our Service.
              </p>
              <button
                className="mt-8 px-6 py-3 bg-primary text-white font-semibold rounded hover:bg-slate-600 cursor-pointer"
                onClick={() => navigationManager("terms")}
              >
                Show Details
              </button>
            </div>
            <div className="bg-white p-4 shadow-lg rounded-lg flex flex-col justify-between">
              <div>
                <h3 className="text-2xl font-semibold text-primary">Policy</h3>
                <p className="mt-4 text-gray-600">
                  Read the Privacy Policy for more information.
                </p>
              </div>
              <button
                className="mt-8 px-6 py-3 bg-primary text-white font-semibold rounded hover:bg-slate-600 w-fit cursor-pointer"
                onClick={() => navigationManager("policy")}
              >
                Show Details
              </button>
            </div>
            <div className="bg-white p-4 shadow-lg rounded-lg flex flex-col justify-between">
              <div>
                <h3 className="text-2xl font-semibold text-primary">Refund</h3>
                <p className="mt-4 text-gray-600">
                  Read the Refund Policy for more information.
                </p>
              </div>
              <button
                className="mt-8 px-6 py-3 bg-primary text-white font-semibold rounded hover:bg-slate-600 w-fit cursor-pointer"
                onClick={() => navigationManager("refund")}
              >
                Show Details
              </button>
            </div>
          </div>
        </section>
      </div>

      {/* Footer Section */}
      <footer className="bg-gradient-to-l from-primary to-[#ef4444] text-primary py-8 pb-0">
        <div className=" container mx-auto flex flex-row-reverse gap-8 px-6 pb-10">
          {/* Head Office Section */}
          <div className="border-2 bg-white border-white rounded-lg p-6">
            <h3 className="text-lg font-semibold mb-4">Head Office</h3>
            <p className="text-sm">
            Players Turf, A-103, SATELLITE PARK CHS LTD,
              <br />
              CAVES ROAD, JOGESHWARI EAST,
              <br />
              Mumbai City, Maharashtra
              <br />
              400060
            </p>
          </div>

        </div>
          {/* Copyright Section */}
          <div className="text-center w-full py-1.5 border-t border-[#ef4444] text-white">
            <p className="text-sm font-bold">
              © 2024 Players Turf. All rights reserved.
            </p>
          </div>
      </footer>
    </div>
  );
};

export default Home;
