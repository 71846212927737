import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import BannerSlider from '../Components/BannerSlider'
import PromotionsSlider from '../Components/PromotionsSlider'
import VenueSlider from '../Components/VenueSlider'
import LocationModel from '../Models/LocationModel'
import { getSportsApi } from '../Utils/API'
import { setSports } from '../Utils/Redux/sportSlice/sportSlice'

function Booking() {
    const allSports = useSelector(state => state.sport.sports)
    const [openPermissionModel, setOpenPermissionModel] = useState(false);
    const userLocation = useSelector(state => state.user.userLocation)
    const dispatch = useDispatch()
    const location = useLocation();
    const data = location.state

    const getAllSports = async () => {
        await getSportsApi().then(res => {
            if (res?.data?.status == 'success') {
                dispatch(setSports(res?.data?.data))
            } else {
                dispatch(setSports([]))
            }
        })
    }

    useEffect(() => {
        getAllSports()
    }, [])

    return (
        <div className='bg-slate-50 col-span-2 md:p-4 md:m-10 shadow-xl rounded-md'>
            {/* ========================= Banners Section ============================ */}
            <BannerSlider />
            {/*========================= Activities Section ========================== */}
            <div className='p-2'>
                <span className='font-semibold text-xl capitalize'>Activites</span>
                <div className='grid grid-cols-1 place-items-center md:grid-cols-4 md:m-3 gap-4'>
                    {
                        allSports?.map((item, index) => (
                            <NavLink
                                to={`/explor?sport=${item?.sport_name}`}
                                state={item}
                                key={index}
                                className="w-[80%] h-[100%] flex flex-col items-center justify-center rounded-lg shadow-md p-4"
                                style={{ backgroundColor: item?.backgroundColor }}
                            >
                                <div className="w-12 h-12 bg-white flex items-center justify-center rounded-full mb-4">
                                    <img
                                        src={item?.sport_image}
                                        alt={`${item?.sport_name} Icon`}
                                        className="w-8 h-8"
                                    />
                                </div>
                                <h2
                                    className="font-bold text-lg capitalize"
                                    style={{ color: item?.color }}
                                >
                                    {item?.sport_name}
                                </h2>
                                <p className="text-sm" style={{ color: item?.color }}>
                                    {item?.venue_count} Center
                                </p>
                            </NavLink>
                        ))
                    }
                </div>

            </div>
            {/* ========================= Promotions Section ============================ */}
            <PromotionsSlider />
            {/*========================= Nearby Venues Section ========================== */}
            <div className='space-y-4 mt-4'>
                <span className='font-semibold text-xl capitalize'>Nearby venues</span>
                <VenueSlider />
            </div>
            {
                userLocation == null && <LocationModel
                    open={openPermissionModel}
                    setOpen={setOpenPermissionModel}
                />
            }
        </div>
    )
}

export default Booking