import { ArchiveBook, ArrowDown2, Edit, Location, LogoutCurve } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../Assets/Images/logo.jpeg';
import UserImage from '../Assets/Images/UserImage.png';
import EditProfile from '../Models/EditProfile';
import { pincodeVenue } from '../Utils/API';
import { setAllVenues } from '../Utils/Redux/sportSlice/sportSlice';
import { setUserLocation } from '../Utils/Redux/userSlice/userSlice';
import Login from './Login';
import LogoutModal from './LogoutModal';

export default function Navbar() {
    const Userdetails = useSelector(state => state.user?.userDetails)
    const userLocation = useSelector(state => state.user.userLocation)
    const allLocations = useSelector(state => state.sport.allLocations)
    // const transformedLocations = allLocations?.map((location) => ({
    //     label: `${location.city} (${location.pin_code})`,
    //     value: location.pin_code,
    // }));
    const [openSlide, setOpenSlide] = useState(false)
    const [card, setCard] = useState(false);
    const [open, setOpen] = useState(false);
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [locationOpen, setLoctionOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logOut = () => {
        setCard(!card);
        setOpen(!open);
    };

    const toggleModal = () => {
        setCard(false);
        setIsModalOpen(!isModalOpen)
    };

    const login = () => {
        setOpenLoginModal(!openLoginModal)
    }

    const getAllNearByVenues = async () => {
        try {
            await pincodeVenue(userLocation?.pin_code).then(res => {
                dispatch(setAllVenues(res?.data?.data))
            })
        } catch (error) {
            console.log('Error', error)
        }
    }

    const handleChange = (selected) => {
        setSelectedOption(selected);
        setLoctionOpen(false)
        dispatch(setUserLocation(selected))
    };

    const handleBooking = () => {
        navigate('/bookingsDetail')
        setCard(false);
    }

    useEffect(() => {
        getAllNearByVenues()
    }, [userLocation]);

    return (
        <>
            <header className='bg-white shadow-xl md:block sticky top-0 z-10'>
                <nav className='flex justify-between items-center mx-12 xxl:m-auto  max-w-page'>
                    <div className='relative flex items-center xl:min-w-[400px] w-1/3'>
                        <NavLink to='/booking' className='cursor-pointer w-[98px]'>
                            <img src={'https://players-turf-storage.s3.ap-south-1.amazonaws.com/profile/Super_logo.jpeg'} alt="Logo" />
                        </NavLink>
                        <button onClick={() => setLoctionOpen(!locationOpen)} className='ml-4 cursor-pointer'>
                            <div className='bg-surface border-2 bg-slate-100 border-slate-300 flex items-center rounded-2xl pl-4 py-2 my-2 md:my-0 pr-6 relative w-fit'>
                                <div className='relative'>
                                    <div className='flex flex-row items-center overflow-hidden outline-none md:h-6 xl:w-80'>
                                        <Location size="20" color="#000" />
                                        <span className='ml-2 capitalize truncate max-w-[170px] md:max-w-full w-fit font-medium leading-6'>{userLocation?.city}</span>
                                    </div>
                                </div>
                            </div>
                        </button>
                        {
                            locationOpen && (
                                <div className="absolute top-[70%] left-[25%] mt-1 bg-white rounded-lg shadow-lg border border-gray-200 w-full max-w-xs z-50">
                                    {/* Search Bar */}
                                    <div className="p-3 border-b border-gray-200">
                                        <input
                                            type="text"
                                            placeholder="Select for cities, places..."
                                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                                        />
                                    </div>

                                    {/* Dropdown Options */}
                                    <div className="max-h-64 overflow-y-auto">
                                        {allLocations.map((location, index) => (
                                            <div
                                                key={index}
                                                onClick={() => handleChange(location)}
                                                className="flex items-center px-4 py-2 cursor-pointer hover:bg-green-50"
                                            >
                                                <Location size="20" className="mr-3 text-gray-600" />
                                                <span className="text-sm text-gray-700">{location.city} ({location?.pin_code})</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <div
                        className={`flex justify-end md:justify-evenly bg-white fixed right-0 py-4 px-4 ${openSlide ? "z-0" : "z-50"} md:px-3 pl-[36px] pr-[36px] w-full md:w-[17rem] lg:w-[27rem]`}
                    >
                        <div className="flex items-center justify-center" onClick={login}>
                            <div onClick={() => setCard((prev) => !prev)} className="flex cursor-pointer items-center">
                                <div className="flex-shrink-0">
                                    <img                                        
                                        className="object-cover w-10 h-10 rounded-full"
                                        src={UserImage}
                                        alt="user"
                                    />
                                </div>
                                <div className="hidden w-full ml-3 space-y-1 lg:block">
                                    <h4 className="capi text-base font-semibold leading-none font-tb whitespace-nowrap text-slate-800">
                                        {Userdetails != undefined ? `${Userdetails?.first_name} ${Userdetails?.last_name}` : "Login / Signup"}
                                    </h4>
                                </div>
                                {Userdetails?.first_name && (
                                    <button
                                        className="hidden px-3 lg:block"

                                    >
                                        <ArrowDown2
                                            size={22}
                                            className={`text-black transition-transform duration-300 ${card ? "rotate-0" : "rotate-180"}`}
                                        />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {Userdetails !== undefined && (<div
                        className={`${card ? "top-20 opacity-100" : "-top-5 opacity-0"
                            } bg-white transition-all ease-in-out duration-700 fixed shadow-sm border right-6 py-2 rounded-lg z-20 px-4 lg:px-6 lg:py-1 mt-5`}
                    >
                        <div className="flex items-center pb-3 border-slate-200 lg:hidden">
                             <div className="flex-shrink-0">
                                <img
                                    className="object-cover w-10 h-10 rounded-full"
                                    src={UserImage}
                                    alt="user"
                                />
                            </div> 
                             <div className="ml-3 space-y-1">
                                <h4 className="text-sm font-semibold leading-none md:text-base font-tb text-slate-800">
                                    {Userdetails?.first_name} {Userdetails?.last_name}
                                </h4>
                            </div> 
                        </div>
                        <div className="p-2 pb-0">
                            <button className="flex items-center pb-2 border-b space-x-2 group" onClick={toggleModal}>
                                <Edit
                                    size={20}
                                    className="text-gray-700 group-hover:text-red-500"
                                />
                                <h4 className="text-sm font-semibold capitalize font-tb lg:text-lg md:text-base group-hover:text-red-500 text-slate-700">
                                    Edit Profile
                                </h4>
                            </button>
                            <button className="flex items-center pb-2 pt-1 border-b space-x-2 group" onClick={handleBooking}>
                                <ArchiveBook
                                    size={20}
                                    className="text-gray-700 group-hover:text-red-500"
                                />
                                <h4 className="text-sm font-semibold capitalize font-tb lg:text-lg md:text-base group-hover:text-red-500 text-slate-700">
                                    My Bookings
                                </h4>
                            </button>
                            <button onClick={logOut} className="flex items-center pb-2 pt-1 space-x-2 group">
                                <LogoutCurve
                                    size={20}
                                    className="text-gray-700 group-hover:text-red-500"
                                />
                                <h4 className="text-sm font-semibold capitalize font-tb lg:text-lg md:text-base group-hover:text-red-500 text-slate-700">
                                    Logout
                                </h4>
                            </button>
                        </div>
                    </div>)}
                </nav>
            </header>
            {/* Logout Modal */}
            <LogoutModal setOpen={setOpen} open={open} />

            {/* Login Modal */}
            <Login setOpenLoginModal={setOpenLoginModal} openLoginModal={openLoginModal} />

            {/*Edit Profile Modal */}

            <EditProfile isOpen={isModalOpen} toggle={toggleModal} />
        </>
    )
}
