import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { EditUserProfile } from '../Utils/API';
import { fileinput2, formBtn1, formBtn2, inputClass, labelClass } from '../Utils/CustomClasses';
import { setUserDetails } from '../Utils/Redux/userSlice/userSlice';
import { validatePhoneNumber } from '../Utils/Validation';
import Error from '../pages/Error';

export default function EditProfile({ isOpen, toggle }) {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const Userdetails = useSelector(state => state.user?.userDetails)
    const userLocation = useSelector(state => state.user.userLocation)
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        const payload = {
            "first_name": data.first_name,
            "last_name": data.last_name,
            "email": data.email,
            "phone": data.phone,
            "address": data.address,
            "profile_image": '',
            "city": data.city,
            "state": data.state,
            "country": data.country,
            // "gender": data?.gender
        }
        const updatedData = {
            ...payload,
            pin_code: userLocation?.pin_code
        }
        try {
            var response = await EditUserProfile(updatedData, Userdetails?.id)
            console.log('response', response)
            if (response?.status === 200) {
                toast.success(response?.data?.message)
                dispatch(setUserDetails(response?.data?.data))
                reset();
                toggle();
            } else {
                toast.error(response?.data?.message)
            }
        } catch (error) {
            console.log('Error', error)
        }
    }

    useEffect(() => {
        if (isOpen) {
            reset({
                "first_name": Userdetails?.first_name,
                "last_name": Userdetails?.last_name,
                "email": Userdetails?.email,
                "phone": Userdetails?.phone,
                "address": Userdetails?.address,
                "city": Userdetails?.city,
                "state": Userdetails?.state,
                "country": Userdetails?.country,
                "pin_code": userLocation?.pin_code
            });
        } else {
            reset();
        }
    }, [isOpen])
    return (
        <>
            <Transition appear show={isOpen} as={Fragment} >
                <Dialog as='div' className='relative z-[100]' onClose={toggle}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-scroll ">
                        <div className='flex items-center justify-center min-h-full p-4 text-center'>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-2xl overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl"
                                >
                                    <Dialog.Title
                                        as="h2"
                                        className="w-full px-3 py-4 text-lg font-semibold leading-6 text-white bg-[#FE0201] font-tb"
                                    >Edit Profile</Dialog.Title>
                                    <div className=" bg-gray-200/70">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="p-4 overflow-y-scroll scrollbars ">
                                                <div className="grid py-4 mx-4 md:grid-cols-1 lg:grid-cols-2 gap-x-3 gap-y-3 customBox">
                                                    <div className="">
                                                        <label className={labelClass}>
                                                            First Name<span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className={inputClass}
                                                            {...register('first_name', { required: true })}
                                                        />
                                                        {errors.first_name && <Error title='First Name is Required*' />}
                                                    </div>
                                                    <div className="">
                                                        <label className={labelClass}>
                                                            Last Name<span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className={inputClass}
                                                            {...register('last_name', { required: true })}
                                                        />
                                                        {errors.last_name && <Error title='Last Name is Required*' />}
                                                    </div>
                                                    <div className="">
                                                        <label className={labelClass}>
                                                            Email Id<span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={inputClass}
                                                            {...register("email", { required: true })}
                                                        />
                                                        {errors.email && <Error title='Email is Required*' />}
                                                    </div>
                                                    <div className="">
                                                        <label className={labelClass}>
                                                            Number<span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <input
                                                            disabled
                                                            type='text'
                                                            className={inputClass}
                                                            maxLength={10}
                                                            {...register("phone", {
                                                                required: "Number is required",
                                                                validate: validatePhoneNumber, // Use your validation function
                                                            })}
                                                        />
                                                        {errors.phone && <Error title='Phone Number is Required*' />}
                                                    </div>
                                                    <div className="">
                                                        <label className={labelClass}>
                                                            Address<span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className={inputClass}
                                                            {...register('address', { required: true })}
                                                        />
                                                        {errors.address && <Error title='Address is Required*' />}
                                                    </div>
                                                    <div className="">
                                                        <label className={labelClass}>Gender<span style={{ color: "red" }}>*</span></label>
                                                        <div className="flex items-center mt-2">
                                                            <label className="flex items-center mr-5">
                                                                <input
                                                                    type="radio"
                                                                    value="male"
                                                                    {...register('gender', { required: true })}
                                                                    className={inputClass + " mr-1"}
                                                                />
                                                                Male
                                                            </label>
                                                            <label className="flex items-center">
                                                                <input
                                                                    type="radio"
                                                                    value="female"
                                                                    {...register('gender', { required: true })}
                                                                    className={inputClass + " mr-1"}
                                                                />
                                                                Female
                                                            </label>
                                                            <label className="flex items-center">
                                                                <input
                                                                    type="radio"
                                                                    value="other"
                                                                    {...register('gender', { required: true })}
                                                                    className={inputClass + " mr-1 ml-2"}
                                                                />
                                                                Other
                                                            </label>
                                                        </div>
                                                        {errors.gender && <Error title="Gender is Required*" />}
                                                    </div>
                                                    <div className="">
                                                        <label className={labelClass}>
                                                            City<span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className={inputClass}
                                                            {...register('city', { required: true })}
                                                        />
                                                        {errors.city && <Error title='City is Required*' />}
                                                    </div>
                                                    <div className="">
                                                        <label className={labelClass}>
                                                            State<span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className={inputClass}
                                                            {...register('state', { required: true })}
                                                        />
                                                        {errors.state && <Error title='State is Required*' />}
                                                    </div>
                                                    <div className="">
                                                        <label className={labelClass}>
                                                            Country<span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className={inputClass}
                                                            {...register('country', { required: true })}
                                                        />
                                                        {errors.country && <Error title='Country is Required*' />}
                                                    </div>
                                                    <div className="">
                                                        <label className={labelClass}>
                                                            Pincode<span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className={inputClass}
                                                            disabled
                                                            {...register('pin_code', { required: true })}
                                                        />
                                                        {errors.pin_code && <Error title='Pincode is Required*' />}
                                                    </div>
                                                    <div>
                                                        <label className={labelClass}>
                                                            Profile Pic
                                                        </label>
                                                        <input
                                                            type="file"
                                                            className={fileinput2}
                                                            accept='image/jpeg,image/jpg,image/png, pdf'
                                                            placeholder='Upload File'
                                                            {...register('profile_image')}
                                                        />
                                                        {errors?.file && <Error title='Document is required' />}
                                                    </div>
                                                </div>
                                            </div>
                                            <footer className="flex justify-end px-4 py-2 space-x-3 bg-white">
                                                <button
                                                    type="submit"
                                                    className={`${formBtn1} relative px-4 py-2 text-white font-bold rounded-lg flex items-center justify-center`}
                                                >
                                                    <span className="opacity-100">
                                                        Submit
                                                    </span>
                                                </button>
                                                <button
                                                    type="button"
                                                    className={formBtn2}
                                                    onClick={toggle}
                                                >
                                                    Close
                                                </button>
                                            </footer>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
