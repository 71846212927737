import { Dialog, Transition } from '@headlessui/react';
import { X } from 'lucide-react';
import moment from 'moment';
import React, { Fragment, useRef } from 'react';

function SportPriceModel({ open, setIsOpen, sportPrice, item, venueDetails }) {
    const cancelButtonRef = useRef(null);
    const price = sportPrice?.filter(price => price?.sport == item?.id)
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[99]"
                initialFocus={cancelButtonRef}
                onClose={() => setIsOpen(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-0 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-[99] w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white space-y-2 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900 font-tbPop w-full border-b-2 pb-1">
                                            <div className='flex flex-row items-center justify-between' >
                                                <span>{item?.sport_name}</span>
                                                <button type='button' onClick={() => setIsOpen(false)}><X /></button>
                                            </div>
                                        </Dialog.Title>
                                    </div>
                                    <Dialog.Panel>
                                        <p className='px-2 border-2 rounded-3xl'>Pricing is subjected to change and is controlled by venue</p>
                                        <div className='px-2 mt-8'>


                                            {
                                                price?.length > 0 ? <>
                                                    <p className='capitalize text-sm'>
                                                        Monday - Sunday
                                                    </p>
                                                    <div className='grid grid-cols-2'>
                                                        {price?.map(priceItem => <p className='font-semibold mt-2'>INR {priceItem?.sport_price} / hour</p>)}
                                                        <p className='text-right font-semibold mt-2'>{moment(venueDetails?.booking_opening_time, "HH:mm:ss").format("hh:mm A")} - {moment(venueDetails?.booking_closing_time, "HH:mm:ss").format("hh:mm A")}</p>
                                                    </div>
                                                </> : <p className='capitalize'> Price's Not given</p>
                                            }

                                        </div>
                                    </Dialog.Panel>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default SportPriceModel;
