import React, { useEffect, useMemo, useState } from 'react';
import turfimage from '../Assets/Images/slider-image.jpg';
import { getBookingsList } from '../Utils/API';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { NavLink } from 'react-router-dom';

export default function AllBookings() {
    const userDetails = useSelector(state => state.user?.userDetails);
    const [selectedTab, setSelectedTab] = useState(0);
    const [userBooking, setUserBooking] = useState([]);
    const [filteredBooking, setFilteredBooking] = useState([]);

    useEffect(() => {
        const filteredData = {
            page: 1,
            user: userDetails?.id
        };
        const fetchData = async () => {
            try {
                const res = await getBookingsList(filteredData);
                if (res?.results?.length > 0) {
                    setUserBooking(res?.results);
                }
            } catch (error) {
                console.log('Failed to fetch data', error);
            }
        };
        fetchData();
    }, []);

    useMemo(() => {
        // Filter bookings based on selected tab
        const filteredBookings = userBooking?.filter(booking => {
            if (selectedTab === 0) {
                return booking?.payment_status === 'Paid';
            } else {
                return booking?.payment_status === 'Pending';
            }
        });
        setFilteredBooking(filteredBookings);
    }, [selectedTab, userBooking]);

    const BookingCard = ({ booking }) => (
        <NavLink to='/booking_summary' state={booking} className="p-4 bg-white border border-red-500 rounded-lg shadow-md mx-4">
            <div className="text-red-600 font-bold text-lg mb-2 text-center">
                Court
            </div>
            <div className="bg-red-100 text-red-600 font-semibold text-center py-2 px-3 rounded mb-4">
                {booking?.booking_id}
            </div>
            <div className="flex flex-col sm:flex-row gap-4 items-start mb-4">
                <img
                    src={turfimage}
                    alt="Turf"
                    className="w-full sm:w-[15rem] h-auto rounded object-cover"
                />
                <div className="flex justify-between w-full">
                    <div>
                        <p className="text-gray-800 font-bold text-lg sm:text-left text-start">
                            {booking?.venue?.name}
                        </p>
                        <p className="text-gray-600 text-sm text-start sm:text-left">
                            {moment(booking?.create_datetime).format('MMMM DD, YYYY')}
                        </p>
                        <p className="text-gray-600 text-sm sm:text-left text-start">
                            {booking?.time_slot}
                        </p>
                    </div>
                    <div className="mt-2 text-center sm:text-left">
                        <span
                            className={`text-sm font-semibold px-3 py-1 rounded ${booking?.payment_status === 'Paid'
                                ? 'bg-green-200 text-green-600'
                                : 'bg-yellow-200 text-yellow-800'
                                }`}
                        >
                            {booking?.payment_status}
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center">
                <p className="text-gray-700 font-medium text-sm">
                    {booking?.venue?.name} ({booking?.sport_name})
                </p>
                <p className="text-gray-800 font-bold text-lg text-right">
                    ₹ {booking?.amount_paid}
                </p>
            </div>
        </NavLink>
    );

    return (
        <>
            <h1 className="text-gray-800 font-bold text-2xl ml-4 mt-5">Bookings Details</h1>
            <Tabs
                selectedIndex={selectedTab} // Controlled by state
                onSelect={(index) => setSelectedTab(index)} // Updates state when the tab changes
            >
                <TabList className="grid grid-cols-2 md:grid-cols-8 lg:grid-cols-8 border-b border-gray-200 mt-2 bg-white shadow">
                    <Tab
                        className={`py-2 px-4 text-center cursor-pointer transition duration-300 ease-in-out ${selectedTab === 0
                            ? 'border-b-2 border-red-500 text-red-500 font-bold'
                            : 'text-gray-600 hover:text-red-500'
                            }`}
                    >
                        Paid
                    </Tab>
                    <Tab
                        className={`py-2 px-4 text-center cursor-pointer transition duration-300 ease-in-out ${selectedTab === 1
                            ? 'border-b-2 border-red-500 text-red-500 font-bold'
                            : 'text-gray-600 hover:text-red-500'
                            }`}
                    >
                        Pending
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className="bg-gray-50 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-8 min-h-screen mt-3">
                        {filteredBooking?.map((booking, index) => (
                            <BookingCard key={index} booking={booking} />
                        ))}
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="bg-gray-50 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-8 min-h-screen mt-3">
                        {filteredBooking?.map((booking, index) => (
                            <BookingCard key={index} booking={booking} />
                        ))}
                    </div>
                </TabPanel>
            </Tabs>
        </>
    );
}
