import React from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { A11y, Navigation, Pagination, Scrollbar, Autoplay, Mousewheel } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { NavLink } from 'react-router-dom'
import { images } from '../Utils/Images'
import { Star } from 'lucide-react'
import { useSelector } from 'react-redux'
function VenueSlider() {
    const venues = useSelector(state => state.sport.allVenues)

    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={3}
            modules={[Navigation, Pagination, Scrollbar, A11y, Mousewheel]}
            navigation
            pagination={{ clickable: true }}
            mousewheel={true}
            breakpoints={{
                320: { // For small screens
                    slidesPerView: 1,
                },
                768: { // For medium screens
                    slidesPerView: 2,
                },
                1024: { // For large screens
                    slidesPerView: 3,
                },
            }}
            onSlideChange={() => console.log('changes')}
            onSwiper={(swiper) => console.log(swiper)}
        >
            {venues?.map((venue) => (
                <SwiperSlide key={venue?.id}>
                    <NavLink to="/venue_screen" state={{ ...venue, isBooking: false }} className="max-w-sm bg-white rounded-lg shadow-md overflow-hidden border">
                        <div className="relative">
                            <img
                                src={venue?.profile_image[0]?.media_url}
                                alt='Turf 1'
                                className="w-full h-48 object-cover"
                            />

                        </div>
                        <div className="p-4">
                            <div className="flex justify-between items-center">
                                <h2 className="text-lg font-semibold">{venue?.name}</h2>
                                {/* <div className="flex flex-row gap-2 items-center bg-green-500 text-white text-sm px-2 py-1 rounded-lg">
                                    <span>{venue?.rating}</span>
                                    <Star size={16} color="#ffff" fill="white" />
                                </div> */}
                            </div>
                            <p className="text-gray-500 text-sm font-semibold">
                                {venue?.address}
                            </p>
                            <span className="text-sm mt-1 text-gray-600">{venue?.contact_number}</span>
                            <p className="mt-2 text-sm font-medium">
                                Price start from <span className="text-black">{venue?.price}</span>
                            </p>
                        </div>
                    </NavLink>
                </SwiperSlide>
            ))}

        </Swiper>
    )
}

export default VenueSlider