export const appUrl = {
    getVenueByFilter: 'app-api/venue-pincode/',
    getBanners: 'app-api/banners/',
    getPromotions: 'app-api/promotions/',
    getNearByVenues: 'app-api/venue-filter/',
    getVenueDetails: 'app-api/venues/',
    getSports: 'app-api/sports/',
    getPincode: 'app-api/venue-pincode/',
    getVenueSlots: 'app-api/court-slots/',
    bookSlot: 'app-api/court-booking/',
    partialPaymentUrl: 'app-api/court-partial-booking-payment/',
    normalBookingUrl: 'app-api/court-booking-payment/',
    splitCourtPaymrntUrl: 'app-api/court-split-booking-payment/',
}

export const accountUrl = {
    loginUrl: 'account-api/user-phone-login/',
    registrationUrl: 'account-api/user-phone-registration/',
    editprofileUrl: 'app-api/user-details/',
}

export const configURL = {
    generateOrderIdUrl: 'configuration-api/payment-order-id/',
}

export const adminUrl = {
    bookingDetailsUrl: 'admin-api/court-booking-details/',
    userBookingDetailsUrl: 'admin-api/court-booking-details-filter/',
    filterCourtUrl: 'admin-api/court-venue-sport-filter/',
}

export const PRODUCTION = true
export const RazorPayKey = PRODUCTION ? process.env.REACT_APP_RAZORPAY_LIVE_API_KEY : process.env.REACT_APP_RAZORPAY_TEST_API_KEY