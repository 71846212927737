import { CalendarClock, Landmark, MapPin, ShoppingBag, Volleyball } from 'lucide-react';
import moment from 'moment';
import React from 'react'
import { useLocation } from 'react-router-dom';

function BookingDetails() {
    const location = useLocation();
    const data = location.state;
    return (
        <div className="flex justify-center items-center mt-4">
            <div className="w-full max-w-4xl bg-white shadow-xl rounded-lg p-6 space-y-6 hover:shadow-2xl transition-shadow duration-300 ease-in-out">
                <div className="border-b-2 pb-4 space-y-2 border-gray-200">
                    <p className="capitalize font-semibold text-red-500 text-lg">Booking ID {data?.booking_id}</p>
                    <p className="font-bold text-2xl text-gray-800">{data?.sport_name}</p>
                    <div className='flex items-center gap-2'>
                        <MapPin strokeWidth={1.25} size={20} color='red' />
                        <p className="text-sm text-gray-500">{data?.venue?.address}</p>
                    </div>
                    <div className='flex items-center gap-2'>
                        <CalendarClock strokeWidth={1.25} size={20} color='red' />
                        <p className="text-sm text-gray-500">
                            {data?.time_slot} • {moment(data?.create_datetime).format("MMMM DD, YYYY")}
                        </p>
                    </div>
                    <div className='flex items-center gap-2'>
                        <Volleyball strokeWidth={1.25} size={20} color='red' />
                        <p className="text-sm text-gray-500">{data?.venue_name}</p>
                    </div>

                    {/* Payment Status Section */}
                    <div className="flex items-center gap-2">
                        <p className="font-semibold text-gray-800">Payment Status:</p>
                        <p
                            className={`font-semibold text-white px-3 py-1 rounded-full ${data?.payment_status === 'Paid'
                                ? 'bg-green-500'
                                : data?.payment_status === 'Pending'
                                    ? 'bg-yellow-500'
                                    : 'bg-red-500'
                                }`}
                        >
                            {data?.payment_status}
                        </p>
                    </div>
                </div>
                <div className="pb-4">
                    <p className="font-semibold text-xl text-gray-800">Payment Details</p>
                    <div className="mt-4 space-y-3">
                        <div className="flex justify-between items-center border-b pb-2 border-gray-100 hover:bg-gray-50 transition-all duration-200">
                            <div className="flex items-center gap-3">
                                <ShoppingBag size={20} color="red" strokeWidth={1.25} />
                                <p className="font-semibold text-gray-800">Slot Price</p>
                            </div>
                            <p className="font-semibold text-gray-800">₹ {data?.slot_price}</p>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2 border-gray-100 hover:bg-gray-50 transition-all duration-200">
                            <div className="flex items-center gap-3">
                                <ShoppingBag size={20} color="red" strokeWidth={1.25} />
                                <p className="font-semibold text-gray-800">Addons Price</p>
                            </div>
                            <p className="font-semibold text-gray-800">₹ 0.0</p>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2 border-gray-100 hover:bg-gray-50 transition-all duration-200">
                            <div className="flex items-center gap-3">
                                <Landmark size={20} color="red" strokeWidth={1.25} />
                                <p className="font-semibold text-gray-800">Taxes & Charges</p>
                            </div>
                            <p className="font-semibold text-gray-800">₹ 12.50</p>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2 border-gray-100 hover:bg-gray-50 transition-all duration-200">
                            <p className="font-semibold text-gray-800">Sub Total</p>
                            <p className="font-semibold text-gray-800">₹ 12.50</p>
                        </div>
                    </div>
                </div>

                <div className="flex justify-between items-center text-red-500 border-t-2 pt-4 border-gray-200">
                    <p className="font-semibold text-xl">Grand Total</p>
                    <p className="font-semibold text-xl text-red-600">₹ {data?.amount_paid}</p>
                </div>
            </div>
        </div>

    )
}

export default BookingDetails