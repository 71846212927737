import axios from "./CustomAxios"
import uaxios from 'axios'
import { accountUrl, adminUrl, appUrl, configURL } from "../Assets/Urls/Urls"

const baseURL = process.env.REACT_APP_BASE_API_URL
export const VenueByPincode = async (pincode) => {
    const url = `${baseURL}${appUrl.getVenueByFilter}${pincode}`
    const response = await uaxios.get(url)
    return response
}

export const Banners = async () => {
    try {
        const url = `${baseURL}${appUrl.getBanners}`
        const response = await uaxios.get(url)
        return response
    } catch (error) {
        console.log('error', error)
    }
}

export const Promotions = async () => {
    try {
        const url = `${baseURL}${appUrl.getPromotions}`
        const response = await uaxios.get(url)
        return response
    } catch (error) {
        console.log('Error getting response', error)
    }
}

export const getNearByVenuesApi = async (pincode) => {
    try {
        const url = `${baseURL}${appUrl.getVenueByFilter}?pincode=${pincode}`
        const response = uaxios.get(url)
        return response
    } catch (error) {
        console.log('Error', error)
    }
}

export const getVenueDetailsApi = async (venueId) => {
    try {
        const url = `${baseURL}${appUrl.getVenueDetails}${venueId}/`
        const response = await uaxios.get(url)
        return response
    } catch (error) {
        console.log('error', error)
    }
}

export const getSportsApi = async () => {
    try {
        const url = `${baseURL}${appUrl.getSports}`
        const response = await uaxios.get(url)
        return response
    } catch (error) {
        console.log('error', error)
    }
}

export const getAllPincodeApi = async (params) => {
    try {
        const url = `${baseURL}${appUrl.getPincode}`
        const response = await uaxios.get(url, { params: params })
        return response
    } catch (error) {
        console.log('Error', error)
    }
}

export const pincodeVenue = async (pincode) => {
    const query = { pin_code: pincode }
    try {
        const url = `${baseURL}${appUrl.getNearByVenues}`
        const response = await uaxios.get(url, { params: query })
        return response
    } catch (error) {
        console.log('Error', error)
    }
}

export const getSlots = async (data) => {
    try {
        const url = `${baseURL}${appUrl.getVenueSlots}`
        const response = await uaxios.post(url, data)
        return response
    } catch (error) {
        console.log('Error', error)
    }
}

export const bookCourt = async (data) => {
    try {
        const url = `${baseURL}${appUrl.bookSlot}`
        const response = await axios.post(url, data)
        return response
    } catch (error) {
        console.log('Error', error)
    }
}

export const LoginAPI = async (data) => {
    try {
        const url = `${baseURL}${accountUrl.loginUrl}`
        const reponse = await uaxios.post(url, data)
        return reponse
    } catch (error) {
        console.log('error', error)
        return error
    }
}

export const partialBookingCourtPayment = async (payload) => {
    try {
        const url = `${baseURL}${appUrl.partialPaymentUrl}`;
        const response = await axios.post(url, payload)
        return response.data
    }
    catch (error) {
        console.log("error : ", error);
    }
}

export const paymentOrderId = async data => {
    const paymentOrderIdURL = `${baseURL}${configURL.generateOrderIdUrl}`;
    try {
        const paymentOrderIdResponse = await axios.post(paymentOrderIdURL, data);
        return paymentOrderIdResponse.data.data;
    } catch (error) {
        console.log("error api file========", error);
    }
}

export const normalCourtPayment = async (payload) => {
    try {
        const url = `${baseURL}${appUrl.normalBookingUrl}`;
        const response = await axios.post(url, payload)
        return response.data
    }
    catch (error) {
        console.log("error : ", error);
    }
}

export const splitPaymentCourtPayment = async (payload) => {
    try {
        const url = `${baseURL}${appUrl.splitCourtPaymrntUrl}`;
        const response = await axios.post(url, payload)
        return response.data
    }
    catch (error) {
        console.log("error : ", error);
    }
}


export const EditUserProfile = async (data, id) => {
    try {
        const url = `${baseURL}${accountUrl.editprofileUrl}${id}/`
        const response = await axios.put(url, data)
        return response;

    } catch (error) {
        console.log('error', error)
    }
}

export const getBookingDetails = async (bookingId) => {
    try {
        const url = `${baseURL}${adminUrl.bookingDetailsUrl}${bookingId}`
        const response = await axios.get(url)
        return response.data
    } catch (error) {
        console.log('Error', error)
    }
}

export const getBookingsList = async (filterData) => {
    const filterPayload = {
        "page": filterData?.page || 1,
        "page_size": 10,
        "user": filterData?.user
    }
    try {
        const url = `${baseURL}${adminUrl.userBookingDetailsUrl}`;
        const response = await axios.get(url, { params: filterPayload })
        return response.data
    }
    catch (error) {
        console.log("error : ", error);
    }
}

export const courtVenueSportFilter = async (payload) => {
    const url = `${baseURL}${adminUrl.filterCourtUrl}`;
    try {
        const response = await axios.post(url, payload)
        return response.data
    }
    catch (err) {
        console.log(err);
        throw err?.response?.data
    }
}