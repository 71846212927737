export const validatePhoneNumber = (value) => {
    const isValid = /^(\+91)?[6-9][0-9]{9}$/.test(value);
    if (!isValid) {
        return "Phone Number must be 10-digit";
    }
    return true;
};

export const validatePassword = (value) => {
    if (!value) {
        return 'Password is required*';
    }
    const pattern = /^(?=.*[!@#$%^&*()_+\{\}\[\]:;"'<>,.?~`\\|/\-])(?=.*\d)[A-Z][A-Za-z\d!@#$%^&*()_+\{\}\[\]:;"'<>,.?~`\\|/\-]*$/;
    const trimmedValue = value.trim();
    return pattern.test(trimmedValue) ? true : 'Invalid Password';
};