import Badminton from '../Assets/Images/racket.jpg'
import Stumps from '../Assets/Images/cricketStumps.png'
import Football from '../Assets/Images/football.png'
import PickelBall from '../Assets/Images/pickelball.jpg'
import Turf1 from '../Assets/Images/TurfImages/turf1.jpg'
import Logo from '../Assets/Images/logo.jpeg'
import PaymentSuccess from '../Assets/Images/paymentsuccesstick.json'

export const images = {
    Stump: Stumps,
    Badminton: Badminton,
    Football: Football,
    Pickelball: PickelBall,
    Turf1: Turf1,
    Logo: Logo,
    PaymentSuccess: PaymentSuccess,
}