import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userSlice/userSlice"; // Import the reducer, not the entire slice
import sportReducer from "./sportSlice/sportSlice"; // Import the reducer, not the entire slice

// Combine reducers
const reducers = combineReducers({
    user: userReducer, // Use the reducer
    sport: sportReducer, // Use the reducer
});

// Persist configuration
const persistConfig = {
    key: 'players-turf',
    version: 1,
    storage,
};

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, reducers);

// Configure store
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Disable serializable check if needed
        }),
});

export default store;
