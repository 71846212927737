import React, { useEffect, useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Autoplay, Mousewheel, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Banners } from '../Utils/API'
import { images } from '../Utils/Images'
import { NavLink } from 'react-router-dom'

function BannerSlider() {
    const [banners, setBanners] = useState([])
    useEffect(() => {
        try {
            Banners().then(res => {
                if (res?.data?.status === 'success' && res?.data?.data?.length > 0) {
                    setBanners(res?.data?.data)
                }
            })
        } catch (error) {
            console.log('error', error)
        }
    }, [])

    const handleRedirection = (item) => {
        if (item?.internal_reference_id) {
            // Internal redirection
            return (
                <NavLink
                    to={`/${item?.redirection_type}`}
                    state={{ ...item, isBooking: true }}
                    className="absolute bottom-4 left-4 px-4 py-2 bg-red-600 text-white font-bold rounded-md shadow-lg hover:bg-red-700"
                >
                    {item?.button_text || 'Explore'}
                </NavLink>
            )
        } else if (item.redirect_link) {
            // External redirection
            return (
                <a
                    href={item?.redirect_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="absolute bottom-4 left-4 px-4 py-2 bg-red-600 text-white font-bold rounded-md shadow-lg hover:bg-red-700"
                >
                    {item?.button_text || 'Explore'}
                </a>
            )
        }
        return null
    }

    return (
        <Swiper
            spaceBetween={30}
            slidesPerView={3}
            mousewheel={true}
            autoplay={{
                delay: 5500,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            modules={[Autoplay, Pagination, Mousewheel]}
            breakpoints={{
                320: { // For small screens
                    slidesPerView: 1,
                },
                768: { // For medium screens
                    slidesPerView: 2,
                },
                1024: { // For large screens
                    slidesPerView: 3,
                },
            }}
        >
            {banners?.map((item, index) => (
                <SwiperSlide key={index}>
                    <div className="relative w-96 h-56 rounded-xl overflow-hidden shadow-md">
                        {/* Background Image */}
                        <img
                            src={item.slide_url}
                            alt={item.banner_title}
                            className="absolute inset-0 w-full h-full object-cover"
                        />
                        {/* Overlay */}
                        <div className="absolute inset-0 bg-black bg-opacity-40"></div>
                        {/* Content */}
                        <div className="absolute bottom-16 left-4 text-white">
                            <h2 className="text-lg font-bold">{item.banner_title}</h2>
                            <p className="text-sm truncate">
                                📍 {item.internal_reference_id?.address || 'No Address'}
                            </p>
                        </div>
                        {/* Button */}
                        <button className="absolute bottom-4 left-4 px-4 py-2 bg-red-600 text-white font-bold rounded-md shadow-lg hover:bg-red-700">
                            {item.button_text}
                        </button>
                    </div>
                    {handleRedirection(item)}
                </SwiperSlide>

            ))}

        </Swiper>
    )
}

export default BannerSlider