import { Share2, Star } from 'lucide-react'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getVenueDetailsApi } from '../Utils/API'
import SportPriceModel from '../Models/SportPriceModel'
import { useDispatch, useSelector } from 'react-redux'
import { setBookingCourt } from '../Utils/Redux/sportSlice/sportSlice'
import Login from './Login'

export default function Venue() {
    const location = useLocation()
    const data = location.state;
    const isLoggedIn = useSelector(state => state?.user?.isLoggedIn)
    const [priceModel, setPriceModel] = useState(false);
    const [selectedSport, setSelectedSport] = useState(null);
    const [venueDetails, setVenueDetails] = useState();
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const profileImages = venueDetails?.profile_image
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const handleBooking = () => {
        dispatch(setBookingCourt(venueDetails))
        isLoggedIn ? navigate(`/slot_booking?venueId=${venueDetails?.id}`, { state: { venueDetails } }) : setOpenLoginModal(true)
    };
    const openModel = (item) => {
        setSelectedSport(item); // Set the clicked sport
        setPriceModel(true); // Open the modal
    };
    const getDetails = async () => {
        try {
            await getVenueDetailsApi(data?.isBooking ? data?.internal_reference_id?.id : data?.id).then(res => {
                if (res?.data?.status == 'success') {
                    setVenueDetails(res?.data?.data)
                }
            })
        } catch (error) {
            console.log('error: ', error)
        }
    }


    useEffect(() => {
        getDetails();
        dispatch(setBookingCourt(null))
    }, [])
    return (
        <>
            <div className='flex mx-4 lg:mx-20'>
                <div className='grid w-full grid-cols-1 justify-items-center'>
                    <div className='w-full col-span-1 pb-10 px-2 md:px-0 '>
                        <div class="mt-12 md:mt-20">
                            <nav class="flex items-center flex-wrap text-gray-500 text-sm font-medium ">
                                <a class="hover:text-primary hover:underline" href="/">Venues</a>
                                <span class="mx-2">&gt;</span>
                                <a class="hover:text-primary hover:underline" href="/">{venueDetails?.city}</a>
                                <span class="mx-2">&gt;</span>
                                <span>{venueDetails?.name}</span>
                            </nav>
                        </div>
                        <div className='w-full mt-8'>
                            <div className='flex flex-col justify-between w-full'>
                                <div className='grid w-full md:h-24 grid-flow-row-dense grid-cols-3 grid-rows-2 gap-y-1 md:gap-y-0 md:gap-x-5'>
                                    <div className='w-full col-span-3'>
                                        <h1 class="md:font-bold md:text-[32px] md:leading-[36px] font-bold text-[24px] leading-[36px] text-typography overflow-hidden md:whitespace-nowrap whitespace-normal md:line-clamp-1 line-clamp-2 truncate">{data?.internal_reference_id?.name}</h1>
                                    </div>
                                    <div className='flex items-center w-full col-span-3 md:col-span-2'>
                                        <div className='flex flex-col w-full sm:items-start sm:justify-start'>
                                            <div className=" text-[#515455] font-medium text-md ">{venueDetails?.address}</div>
                                            <div className='flex flex-row mt-2 md:mt-3 sm:mr-8 sm:items-center sm:justify-center items-center'>
                                                <p className='mr-1 text-[#515455] text-sm font-semibold'>3.3</p>
                                                <p className='text-sm text-[#515455] font-medium '>(4 Ratings)</p>
                                                <Star size={16} color='#FFD700' fill='#FFD700' />
                                                <p class="ml-2 text-sm font-semibold underline cursor-pointer text-primary">Rate Venue</p>
                                            </div>
                                        </div>
                                    </div>
                                    { /* ============= Bulk Book And Share Buttons */}
                                    <div className='flex z-9 flex-row w-full col-span-3 mt-3 space-x-2 md:mt-0 sm:col-span-2 md:col-span-1 '>
                                        <div className='flex flex-col items-center justify-start w-full space-y-3'>
                                            <button onClick={handleBooking} class="w-full h-12 px-3 py-2 font-semibold text-white rounded-lg bg-primary" alt="Book now Button" aria-label="Book Now">Book Now</button>
                                            {/* <div className='flex flex-row items-center justify-start w-full space-x-2'>
                                                <button className="flex items-center justify-center w-full h-12 space-x-2 rounded-lg font-semibold text-black border-2 cursor-pointer">
                                                    <Share2 />
                                                    <span>
                                                        Share
                                                    </span>
                                                </button>
                                                <button className="w-full h-12 px-3 py-2 font-semibold text-sm md:text-md border-primary border text-primary rounded-md bg-white">Bulk / Corporate</button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='grid w-full grid-cols-1 gap-2 mt-6 md:gap-x-5 md:grid-cols-3'>
                                <div className='hidden w-full row-span-1 bg-opacity-50 md:block md:col-span-2 '>
                                    <div className="overflow-hidden aspect-video rounded-md w-full">
                                        <Swiper
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                                            navigation
                                            pagination={{ clickable: true }}
                                            onSlideChange={() => console.log('Slide changed')}
                                            onSwiper={(swiper) => console.log('Swiper instance:', swiper)}
                                            className="w-full h-full col-span-2"
                                        >
                                            {profileImages?.map((image) => (
                                                <SwiperSlide key={image?.id}>
                                                    <img
                                                        src={image?.media_url}
                                                        alt={image?.media_name || 'Profile Image'}
                                                        className="w-full h-full object-cover"
                                                    />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                                <div className='w-full z-0 md:row-span-2'>
                                    <div className='flex flex-col md:mt-14 '>
                                        <div className='flex flex-col p-4 border border-gray-400 rounded-md'>
                                            <h2 class="font-semibold text-md md:text-lg">Timing</h2>
                                            <div className="mt-2 leading-1">
                                                {moment(venueDetails?.booking_opening_time, "HH:mm:ss").format("hh:mm A")} - {moment(venueDetails?.booking_closing_time, "HH:mm:ss").format("hh:mm A")}
                                            </div>

                                        </div>
                                        <div className='flex flex-col h-auto p-4 mt-5 border border-gray-400 rounded-md'>
                                            <div class="font-semibold text-md md:text-lg">Location</div>
                                            <h2 class="my-2">{venueDetails?.address}- {venueDetails?.pin_code}</h2>
                                            <div>
                                                <iframe
                                                    src={`https://www.google.com/maps/embed/v1/view?key=${process.env.REACT_APP_GOOGLE_API_KEY}&center=${data?.internal_reference_id?.latitude},${data?.internal_reference_id?.longitude}&zoom=14`}
                                                    width="100%"
                                                    height="300"
                                                    style={{ border: 0 }}
                                                    allowFullScreen=""
                                                    loading="lazy"
                                                    referrerPolicy="no-referrer-when-downgrade"
                                                ></iframe>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='w-full rounded-lg md:row-span-5 md:col-span-2'>
                                    <div className='p-6 mt-4 border border-gray-400 rounded-md'>
                                        <div className='flex flex-col justify-start md:items-center md:flex-row'>
                                            <h2 class="font-semibold text-md md:text-lg">Sports Available</h2>
                                            <div class="text-sm text-gray-500 md:ml-2">(Click on sports to view price chart)</div>
                                        </div>
                                        <div className='grid items-center w-full grid-cols-3 gap-5 mt-5 sm:gap-6 sm:grid-cols-5 lg:gap-6 xl:grid-cols-7 border-gray-400 rounded-md'>
                                            {venueDetails?.sport_available_text?.map(item =>
                                                <div
                                                    key={item?.id}
                                                    onClick={() => openModel(item)}
                                                    className='flex flex-col items-center py-1 border rounded shadow-md cursor-pointer hover:border-primary hover:border aspect-square border-border_color'
                                                >
                                                    <img src={item?.sport_image} alt={item?.sport_name} />
                                                    <h3 className='flex justify-center w-full mt-1 text-xs font-medium text-center text-border_tag capitalize'>
                                                        {item?.sport_name}
                                                    </h3>
                                                </div>)}
                                            <SportPriceModel open={priceModel} setIsOpen={setPriceModel} sportPrice={venueDetails?.sports_price} item={selectedSport} venueDetails={venueDetails} />
                                        </div>
                                    </div>
                                    <div className='flex flex-col mt-5 md:mt-5 '>
                                        <div className='p-6 border border-gray-400 rounded-md'>
                                            <h3 class="font-semibold text-md">Amenities</h3>
                                            <div className='grid grid-cols-2 gap-2 mt-5 md:grid-cols-3 lg:grid-cols-4 gap-y-6'>
                                                {venueDetails?.available_amenities?.map(amenities =>
                                                    <div className='flex items-center space-x-2 text-sm capitalize md:text-md '>
                                                        <img src="https://playo-website.gumlet.io/playo-website-v2/logos-icons/correct.svg" alt={amenities?.amen_name} />
                                                        <h4>{amenities?.amen_name}</h4>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <div className='flex flex-col items-start w-full mt-5'>
                                            <div className='w-full p-5 border border-gray-400 rounded-md'>
                                                <div class="font-semibold text-md">Venue Rules</div>

                                            </div>
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Login openLoginModal={openLoginModal} setOpenLoginModal={setOpenLoginModal} fromBooking={true} />
        </>
    )
}
