import { Provider } from 'react-redux';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';
import ProjectRoutes from './routes/ProjectRoutes';
import store from './Utils/Redux/store';
let persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ProjectRoutes />
      </PersistGate>
    </Provider>
  );
}

export default App;
