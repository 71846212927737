import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    userLocation: null,
    isLoggedIn: false,
    userDetails: undefined,
    userPincode: null,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserLocation(state, action) {
            state.userLocation = action.payload;
        },
        setLogin: (state, action) => {
            state.isLoggedIn = action.payload
        },
        setUserDetails: (state, action) => {
            state.userDetails = action.payload
        },
        setUserPincode: (state, action) => {
            state.userPincode = action.payload
        },
    }
})

export const { setUserLocation, setLogin, setUserDetails, setUserPincode } = userSlice.actions
export default userSlice.reducer